import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: black;
    width: 100%;
    height: 10vh;

    display: none;

    @media(max-width: 1000px)
    {
        display: block;
    }
`;

export default function AboutUsHeader()
{
    return(<Wrapper></Wrapper>)
}