import React, { useEffect } from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const HeroStyle = styled.div`
    width: 100%;
    
    overflow-x: hidden;

    .hero-image{
        overflow-y: hidden;

        width: 100%;
        height: 75vw;
        background: linear-gradient(4.15deg, rgba(0, 0, 0, 0.6) 30.55%, rgba(0, 0, 0, 0) 94.93%);

        display: flex;
        align-items: flex-end;
    }

    @media (max-width: 1000px) {
        .hero-image,
        .hero-image::before,
        .hero-image::after {
            background-position-y: center;
            background-position-x: 45%;
            padding-top: 5vh;
            //height: 100vh;

            //height: 45vh;
            width: 125%;
            left: -12.5%;

            //margin-top: 5vh;
            //padding-bottom: 5vh;
        }

        .brushStroke{
            bottom: -5%;
            width: 5%;
        }
    }

    @media(max-height: 450px){
        .hero-image{
            align-items: flex-start;
        }
    }
`;

const HeroText = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    text-align: center;

    height: 100%;
    width: 100%;

    p {
        margin-top: 2%;

        font-family: D-DINExp;
        font-weight: lighter;
        font-size: calc(1vh + 1.25vw);
        //line-height: 22px;
        color: white;

        letter-spacing: 0.75px;
        max-width: 55vw;
    }

    .video{
        margin-top: 2vh;
        margin-bottom: 7.5vh;

        width: 75vw;
        min-width: 600px;
        height: calc(15vh + 20vw);
    }

    @media (max-width: 1000px) {

        //margin-top: max(35px, 2vh);
        //left: 12.5%;
        justify-content: center;

        .video{
            height: auto;
            margin-top: 0vh;
            margin-bottom: -2vh;
            width: 35%;
            min-width: 0;
        }

        h1{
            font-size: calc(2vh + 2vw);
            margin-bottom: 1%;

        }
        p {
            margin-top: 0;
            max-width: 80%;
            font-size: 8px;
        }
    }

    @media(max-height: 600px)
    {
        .video{
            display: none;
        }
    }

    @media(max-height: 450px){
        justify-content: flex-start;
        margin-top: 45vh;

        .video{
            display: inline;
            margin-top: 3vh;
            margin-bottom: 0;
        }
    }
`;

export default function BrandsHero() {
  // ----Gatsby Background Image Set-Up-----
  const { bannerImage } = useStaticQuery(
    graphql`
      query {
        bannerImage: file(relativePath: { eq: "aboutus.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(bannerImage);
  const bgImage = convertToBgImage(image);

    // useEffect(() => {
    //     const firstscript = document.createElement('script');
    //     const secondscript = document.createElement('script');

    //     firstscript.src = 'https://fast.wistia.com/embed/medias/ubevf7v1q3.jsonp';
    //     firstscript.async = true;
    //     secondscript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    //     secondscript.async = true;

    //     document.body.appendChild(firstscript);
    //     document.body.appendChild(secondscript);

    //     return () => {
    //         document.body.removeChild(firstscript);
    //         document.body.removeChild(secondscript);
    //     };

    // }, []);

  return (
    <HeroStyle>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        preserveStackingContext
        className="hero-image"
      >
        <HeroText>
          <h1>About Us</h1>
          <p>
            At Ronin Cannabis, doing good and good business are not competing principles; they are fundamentally aligned core values. 
            <br></br>
            <br></br>
            We are committed to promoting a culture of diversity, education, and giving back through various community-based programs and initiatives. 
          </p>

            <div className="video">
                {/* <iframe width="100%" height="100%" src="https://player.vimeo.com/video/588415581" frameborder="0" allow="autoplay" allowfullscreen="true" title="Ronin Cannabis">
                </iframe> */}

                {/* <script src="https://fast.wistia.com/embed/medias/ubevf7v1q3.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
                <div class="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative"}}>
                    <div class="wistia_responsive_wrapper" style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}>
                        <div class="wistia_embed wistia_async_ubevf7v1q3 videoFoam=true" style={{ height: "100%", position: "relative", width: "100%" }}>
                            <div class="wistia_swatch" style={{ height: "100%", left: 0, opacity: 0, overflow: "hidden", position: "absolute", top: 0, transition: "opacity 200ms", width: "100%" }}>
                                <img src="https://fast.wistia.com/embed/medias/ubevf7v1q3/swatch" style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%"}} alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                            </div>
                        </div>
                    </div>
                </div> */}

                <iframe 
                    src="https://player.vimeo.com/video/617311957?h=557cddd572" 
                    width="100%" 
                    height="100%" 
                    frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowfullscreen
                    webkitallowfullscreen
                    mozallowfullscreen 
                > 
                </iframe>

            </div>

        </HeroText>
      </BackgroundImage>
    </HeroStyle>
  );
}
