import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    position: relative;

    .sideways{
        display: none;
    }

    .hero-image{
        width: 34%;
        height: 100%;
    }

    .hero-image-last{
        width: 34%;
        height: 95%;
    }

    @media (max-width: 1000px){
        flex-direction: column;
        height: 200vh;
        margin-bottom: -90vh;
        .hero-image{
            position: absolute;
            height: 60vh;
            width: 100%;
            margin-bottom: -25vh;
        }
        .hero-image-last{
            width: 125%;
            left: -12.5%;

            height: 50vh;
            margin-top: -5vh;
            margin-bottom: -25vh;
        }
    }

    @media (max-height: 500px){
        height: 150vh;

        .hero-image, .hero-image-last{
            display: none;
        }

        .sideways{
            display: flex;

            width: 125%;
            height: 100vh;
            left: -12.5%;

            background-position-y: 75%;
        }


    }
`;

export default function AboutUsImage()
{
    const { connectImageFile, meditateImageFile, elevateImageFile, threeImageFile } = useStaticQuery(
        graphql`
            query {
                threeImageFile: file(relativePath: { eq: "Three.png" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
                connectImageFile: file(relativePath: { eq: "Connect.png" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
                meditateImageFile: file(relativePath: { eq: "Meditate.png" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
                elevateImageFile: file(relativePath: { eq: "Elevate.png" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
            }
        `
    );

    const connectImage = getImage(connectImageFile);
    const meditateImage = getImage(meditateImageFile);
    const elevateImage = getImage(elevateImageFile);
    const threeImage = getImage(threeImageFile)

    const bgConnectImage = convertToBgImage(connectImage);
    const bgMeditateImage = convertToBgImage(meditateImage);
    const bgElevateImage = convertToBgImage(elevateImage);
    const bgthreeImage = convertToBgImage(threeImage)

    return(
        <Wrapper>
            <BackgroundImage
                Tag="div"
                {...bgthreeImage}
                //preserveStackingContext
                className="sideways"
                style={{
                    backgroundPosition: '75% center',
                }}
            />
            <BackgroundImage
                Tag="div"
                {...bgConnectImage}
                preserveStackingContext
                className="hero-image"
            />
            <BackgroundImage
                Tag="div"
                {...bgMeditateImage}
                preserveStackingContext
                className="hero-image"
            />
            <BackgroundImage
                Tag="div"
                {...bgElevateImage}
                preserveStackingContext
                className="hero-image-last"
            />
        </Wrapper>
    )
}