import React from 'react';
import styled from 'styled-components'
import { graphql, Link } from 'gatsby';


import SEO from '../components/SEO';

import AboutUsHero from '../components/AboutUsHero'
import AboutUsBreak from '../components/AboutUsBreak';
import AboutUsExperience from '../components/AboutUsExperience'
import AboutUsImage from '../components/AboutUsImage';
import AboutUsPeople from '../components/AboutUsPeople';
import BottomHero from '../components/BottomHero';
import AboutUsHeader from '../components/AboutUsHeader';

export default function AboutUsPage({ data })
{

    return(
        <>
            <SEO
                title="About Us"
                description="About us page"
            />

            <AboutUsHeader/>

            <AboutUsHero/>
            
            <AboutUsBreak marginTop="0%">
                <h3>Connect your body. Meditate your mind. <br />Elevate your soul.</h3>
            </AboutUsBreak>

            <AboutUsImage/>

            <AboutUsExperience/>

            <AboutUsBreak marginTop="-1%" imgBottom="-1vh">
                <h3>Meet The Team</h3>
                <p>Our team at Ronin Cannabis wholeheartedly believes in diversity, inclusivity and make giving back to our local communities an absolute priority. We are devoted to growing cannabis culture not only within our city, but across Southwestern Ontario, making Ronin Cannabis a communal hub for everything cannabis related! 
                </p>
            </AboutUsBreak>

            <AboutUsPeople
                gen_managers={data.generalManagers.nodes}
                asst_managers={data.assistantManagers.nodes}
            />

            <BottomHero
                title="Giving Back"
                content={[
                    `We care about being eco-friendly and helping Mother Nature!`,

                    `How we are reducing our carbon footprint:`,

                    `With serving the Tri-Cities and beyond, it is important to us to be environmentally responsible and reduce our overall waste. When shopping at Ronin, you can assure that the shopping bags you are leaving with are 100% recycled with post-consumer content. 
                    Did you know at Ronin Cannabis there are also drop-off bins for customer’s vape recycling and plastic container waste? We are dedicated to reduce, reusing, and recycling with every opportunity we have! Our team advocates for being eco-conscious, focusing on sustainable packaging and recycling programs`,

                    `Community Outreach`,

                    `Ronin Cannabis is dedicated to giving back to our community and highlighting local charities, artists and non-profits through organized events, art shows, workshops, business promotions, donations and fundraisers! Through the growth of community and cannabis culture, we are breaking community barriers by connecting, educating and empowering companies and individuals to get involved, and give back to our vulnerable populations. `,

                    `Keep up to date with all events to come through our blog page and social media outlets.  If you know a charity who deserves to be showcased, please do not hesitate to reach out to our team! 
                    `,
                ]}
            />
        </>
    )

}

export const query = graphql`
    query{
        generalManagers: allSanityEmployee(filter: { job_title: { in: ["General Manager", "General/Operations Manager"] } }) {
            nodes {
                id
                job_title
                name
                description
                headshot{
                    alt
                    image {
                        asset {
                            _id
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        assistantManagers: allSanityEmployee(filter: { job_title: { eq: "Assistant Manager" } }) {
            nodes {
                id
                job_title
                name
                description
                headshot{
                    alt
                    image{
                        asset{
                            _id
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;