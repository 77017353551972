import React from 'react';
import styled from 'styled-components';

import AboutUsEmployee from './AboutUsEmployee';

import BrushStroke from '../../static/svg/BrushStroke.svg'
import AboutUsWage from './AboutUsWage';

const Wrapper = styled.div`
    padding-top: 7.5%;
    padding-bottom: 5%;
    width: 100%;
    background-color: black;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    margin-bottom: 3%;

    .brushStroke{
        width: 100%;
        position: absolute;
        bottom: -5%;
    }

    @media(max-width: 1000px){
        padding-bottom: 20vh;
        .brushStroke{
            bottom: -3vh;
        }
    }

    @media(max-height: 500px){
        .brushStroke{
            bottom: -7.5vh;
        }
    }
`;

const EmployeeWrapper = styled.div`
    margin-top: 5%;
    margin-bottom: 10%;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
`;

export default function AboutUsPeople({ gen_managers, asst_managers })
{

    return(
        <Wrapper>
            <h2>Managers</h2>
            <EmployeeWrapper>
                {gen_managers.map((person, index) => (
                        <AboutUsEmployee employee={person} key={`${person.name}${index}bbb`}/>
                    ))}
            </EmployeeWrapper>

            {asst_managers.length && 
                <>
                    <h2>Assistant Managers</h2>
                    <EmployeeWrapper>
                        {asst_managers.map((person, index) => (
                                <AboutUsEmployee employee={person} key={`${person.name}${index}aa`}/>
                            ))}
                    </EmployeeWrapper>
                </>
            }

            <AboutUsWage/>

            <img src={BrushStroke} className="brushStroke" alt="" />
        </Wrapper>
    )
}