import React from 'react';
import styled from 'styled-components';

import BrushStroke from '../../static/svg/BrushStroke.svg'


const Wrapper = styled.div`
    width: 100%;
    position: relative;
    
    background-color: white;
    min-height: 300px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .brushStrokeTop{
        width: 100%;
        z-index: 2;
        position: absolute;
        top: -1vh;
    }

    .brushStrokeBot{
        width: 100%;
        z-index: 2;
        position: absolute;
        bottom: -6vh;
    }

    h3{
        z-index: 3;
        font-family: Gloss-And-Bloom,serif;
        color: black;
        text-align: center;
        width: 75%;
        font-size: 62px;
        font-weight: normal;
        padding-top: 5%;
    }

    p{
        z-index: 3;
        width: 75%;
        text-align: center;
        
        font-family: D-DINExp;
        font-size: 24px;
        line-height: 22px;
        letter-spacing: 1px;

        padding-bottom: 5vh;
        color: #6D6E71;
    }

    @media (max-width: 1000px) {
        min-height: 50px;

        .brushStrokeTop{
            top: -2vh;
        }
        .brushStrokeBot{
            bottom: -3.5vh;
        }

        h3{
            padding-top: 5vh;
            font-size: 30px;
        }
        p {
            max-width: 80%;
            font-size: 14px;
        }
    }
`;

export default function AboutUsBreak({ children, marginTop, imgBottom })
{
    return(
        <Wrapper
            style={{
                marginTop: marginTop
            }}
        >
            <img src={BrushStroke} className="brushStrokeTop" alt="" />
            {children}
            <img src={BrushStroke} className="brushStrokeBot" alt="" style={{ bottom: imgBottom ? imgBottom : null }}/>
        </Wrapper>
    )
}