import React from 'react';
import styled from 'styled-components';

import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import clientConfig from '../../client-config';


const Wrapper = styled.div`
    width: 40%;
    height: 42vw;

    z-index: 1;

    position: relative;

    overflow: hidden;

    //display: flex;
    //align-items: flex-end;

    .headshot{
        height: 100%;
        width: 100%;

        position: absolute;
    }

    .pinkshadow{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: linear-gradient(355.47deg, #E94799 8.3%, rgba(233, 71, 153, 0) 55.24%);
    }

    .textwrapper{
        z-index: 3;
        position: absolute;

        top: 50%;

        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h2{
            font-size: calc(2vh + 2vw);
        }

        h3{
            margin-top: 2%;

            color: white;
            /* font-family: D-DINExp; */
            font-weight: bold;
            font-size: 24px;
        }

        p{
            width: 95%;
            color: white;
            font-size: calc(0.25vh + 0.6vw);
            /* font-family: D-DINExp !important; */
            font-weight: lighter;
            line-height: 150%;
        }
    }

    @media (max-width: 1000px){
        width: 100%;
        height: 100vw;

        overflow: visible;

        margin-bottom: 60vh;

        .textwrapper{
            top: 75%;

            p {
                width: 95%;
                margin-top: 10vw;
                font-size: calc(1vh + 1.5vw);
            }
        }
    }

    @media (max-height: 500px){
        margin-bottom: 90vh;

        .textwrapper{
            top: 90%;
        }
    }
`;

export default function AboutUsEmployee({ employee })
{
    const gatsbyImageData = getGatsbyImageData(
        employee.headshot.image.asset,
        { maxWidth: 750 },
        clientConfig.sanity
    );
    
    const readyImage = getImage(gatsbyImageData);
    const bgImage = convertToBgImage(gatsbyImageData);
    
    return(
        <Wrapper> 
            {/*  */}
            <GatsbyImage className="headshot" image={employee.headshot.image.asset.gatsbyImageData}/>
            <div className="pinkshadow"></div>
            
            <div className="textwrapper">
                <h2>{employee.name}</h2>
                <h3>{employee.job_title}</h3>
                <p>{employee.description}</p>
            </div>
        </Wrapper>
    )   
}