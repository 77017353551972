import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import BrushStroke from '../../static/svg/BrushStroke.svg'


const Wrapper = styled.div`
    width: 100%;
    z-index: 2;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;

    .brushStroke{
        width: 100%;
        position: absolute;
        top: -5%;
    }

    h1{
        margin-top: 2%;
        z-index: 2;
        color: black;
    }

    .hero-image{
        width: 100%;
        height: 60vw;
        display: flex;
        justify-content: center;
    }

    @media (max-width: 1000px){
        width: 100%;
        .brushStroke{
            top: -2%;
        }
        h1{
            margin-top: 10%;
        }
        .hero-image,
        .hero-image::before,
        .hero-image::after {
            background-position-y: center;
            //background-position-x: 45%;
            width: 100%;
        }
    }
`;

const ImageWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    position: relative;

    padding-top: 12.5%;

    .shadow{
        position: absolute;
        height: 25%;
        width: 100%;
        background: linear-gradient(180deg, rgba(255,255,255, 1), 80%, rgba(255, 255, 255, 0) 100%);
    }

    p{
        z-index: 2;
        position: absolute;
        top: 25px;

        width: 80%;
        white-space: pre-wrap;
        text-align: center;

        font-family: D-DINExp;
        font-size: 24px;
        line-height: 22px;
        letter-spacing: 1px;

        color: #6D6E71;
    }

    @media (max-width: 750px){
        padding-top: 225%;
        p { 
            font-size: 12px;
        }   

        .shadow{
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) -75%, rgba(255, 255, 255, 0) 100%);
        }
    }

    @media(max-height: 450px){
        padding-top: 750px;
    }
`;

export default function AboutUsExperience()
{

    const { bannerImage } = useStaticQuery(
        graphql`
          query {
            bannerImage: file(relativePath: { eq: "AboutUsExperience.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `
      );
      const image = getImage(bannerImage);
      const bgImage = convertToBgImage(image);

    return(
        <Wrapper>
            <img src={BrushStroke} className="brushStroke" alt="" />

            <h1>Experience</h1>

            <ImageWrapper>
                <p>
                    What kind of experience are you looking to achieve? Our knowledgeable staff are here to assist, educate, and empower you to make selections specifically geared towards your cannabis preferences.  
                    <br></br><br></br>
                    Here at Ronin Cannabis, our staff is dedicated to creating a positive, non-judgmental, inclusive space for everyone! High vibes and elevated experiences are what we’re all about! Our certified budtenders are oozing with knowledge and the wiliness to help you achieve the experience you’re looking for.
                    <br></br><br></br>
                    Stimulate your senses and elevate your experience as you walk through our beautiful retail store, bursting with inspired custom-made art and design! 
                    <br></br><br></br>
                    Wanting more knowledge of terpenes? Terpenes are the aromatic oils (in cannabis and in many other plants) that give the distinctive flavours and diverse effects. Check out our terpene bar that highlights some of the main terpenes found in cannabis! 
                    <br></br>
                    Explore our tablets to gain some cannabis knowledge, view our menu, and place an express order!
                </p>
                <div className="shadow"></div>
                <BackgroundImage
                    Tag="div"
                    {...bgImage}
                    preserveStackingContext
                    className="hero-image"
                >
                </BackgroundImage>
            </ImageWrapper>
                
        </Wrapper>
    )
}