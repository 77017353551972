import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Wrapper = styled.div`
    width: 100%;
    height: 25vh;

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .hero{
        width: 25vh;
        height: 25vh;
    }

`;

export default function AboutUsWage()
{
    const { ImageFile } = useStaticQuery(
        graphql`
            query {
                ImageFile: file(relativePath: { eq: "LivingWage.png" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
            }
        `
    );

    console.log('wage os', ImageFile)

    const Image = getImage(ImageFile);

    const bgImage = convertToBgImage(Image)

    return(
        <Wrapper>
            <BackgroundImage
                Tag="div"
                {...bgImage}
                //preserveStackingContext
                className="hero"
            />
            {/* <BackgroundImage
                Tag="div"
                {...bgConnectImage}
                preserveStackingContext
                className="hero-image"
            />
            <BackgroundImage
                Tag="div"
                {...bgMeditateImage}
                preserveStackingContext
                className="hero-image"
            />
            <BackgroundImage
                Tag="div"
                {...bgElevateImage}
                preserveStackingContext
                className="hero-image-last"
            /> */}
        </Wrapper>
    )
}